import { TileRowsProps } from '../../components/Storybook/TileRows'
import { BeespayReportTicketDetailResume } from '../../components/BeespayReportTicketDetailResume'

interface TransformQueryToDataTableGridProps {
  tileRow: TileRowsProps
}

export const transformBeesQueryTicketDetail = (
  ticketSumary: BeesPayTypes.DetailTransaction
): BeesPayTypes.DetailTransactionSummary => {
  const detail = {
    company: ticketSumary.transactionServiceName,
    phoneNumber: ticketSumary.reference,
    commission: ticketSumary.transactionCommission,
    reference: ticketSumary.reference,
    payment:
      ticketSumary.amount -
      (ticketSumary.transactionFee !== undefined
        ? ticketSumary.transactionFee
        : 0),
    typePayment: ticketSumary.transactionTypeID,
    cardDigit: ticketSumary.cardDigit,
    typeCard: ticketSumary.cardBank
      ? `${ticketSumary.cardInstrument} / ${ticketSumary.cardBank}`
      : '--',
    status: ticketSumary.status,
    fee: ticketSumary.transactionFee,
    description: ticketSumary.transactionServiceDescription,
    ticketId: ticketSumary.transactionServiceID,
    authorizationCode: ticketSumary.authorizationCode
  }
  return detail
}

export const transformBeesQueryTicketDetailToComponent = (
  queryData: BeesPayTypes.DetailTransaction
): TransformQueryToDataTableGridProps => {
  const summaryTicket = transformBeesQueryTicketDetail(queryData)
  const tileRow = BeespayReportTicketDetailResume(summaryTicket)

  return { tileRow }
}

export const translateStatus = (status: string): string => {
  switch (status.toUpperCase()) {
    case 'APPROVED':
      return 'Pago completado'
    case 'DENIED':
      return 'Pago rechazado'
    case undefined:
      return 'Estatus no encontrado'
    default:
      return ''
  }
}

export const translateTypeSmall = (type: string): string => {
  switch (type) {
    case 'topup':
      return 'Recarga Teléfonica'
    case 'billPayment':
      return 'Pago'
    case 'cashRegister':
      return 'Venta con Tarjeta'
    case 'b2b':
      return 'Pago de orden Bees'
    case 'cancelation':
      return 'Cancelación exitosa'
    case 'fundWallet':
      return 'Recarga de saldo'
    default:
      return ''
  }
}
