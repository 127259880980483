import styled from 'styled-components'

export const ReportContainer = styled.div`
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #e2e2e2;
  p {
    color: #828282;
  }
  h4.ant-list-item-meta-title {
    font-weight: bold;
    font-size: 16px;
  }
`
export const HourContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ParagraphHour = styled.p`
  color: #828282;
  padding: 0 0 0 1em;
  margin: 0;
`
export const ParagraphDate = styled.p`
  color: #828282;
  margin: 0;
  padding-left: 3em;
`
