/* istanbul ignore file */
import React from 'react'
import { TileRowsProps } from '../Storybook/TileRows'
import { StyledContainerRight } from '../../styles/generalStyledComponents'
import { convertNumberToMoney } from '../../utils/general'
import {
  translateStatus,
  translateTypeSmall
} from '../../containers/BeesPayReportTicketContainer/utils'
import { translateType } from '../../containers/BeesPayReportContainerByDayTickets/utils'

export const BeespayReportTicketDetailResume = (
  summaryTicket: BeesPayTypes.DetailTransactionSummary
): TileRowsProps => {
  let rowBody: any = []
  switch (summaryTicket.typePayment) {
    case 'topup':
      rowBody = [
        {
          parameter: 'Compañia',
          value: (
            <StyledContainerRight>{summaryTicket.company}</StyledContainerRight>
          )
        },
        {
          parameter: 'Número celular',
          value: (
            <StyledContainerRight>
              {summaryTicket.phoneNumber ? summaryTicket.phoneNumber : '-'}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Paquete',
          value: (
            <StyledContainerRight>
              {summaryTicket.description}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Total pagado',
          value: (
            // Its necessary do a refactor. Right now it works but this is a huge mistake.
            <StyledContainerRight>
              {convertNumberToMoney(
                summaryTicket.payment + summaryTicket.fee + summaryTicket.fee
              )}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Comisión BEES Pay',
          value: (
            <StyledContainerRight>
              {summaryTicket.fee !== undefined
                ? convertNumberToMoney(summaryTicket.fee)
                : convertNumberToMoney(0)}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Referencia',
          value: (
            <StyledContainerRight>
              {summaryTicket.authorizationCode}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Pago con tarjeta',
          value: (
            <StyledContainerRight>
              {summaryTicket.cardDigit ? `**${summaryTicket.cardDigit}` : `--`}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Tipo de Tarjeta',
          value: (
            <span>
              {summaryTicket.typeCard ? summaryTicket.typeCard : `--`}
            </span>
          )
        },
        {
          parameter: 'Status',
          value: (
            <StyledContainerRight>
              {translateStatus(summaryTicket.status)}
            </StyledContainerRight>
          )
        }
      ]
      break
    case 'billPayment':
      rowBody = [
        {
          parameter: 'Compañia',
          value: (
            <StyledContainerRight>{summaryTicket.company}</StyledContainerRight>
          )
        },
        {
          parameter: 'Operación',
          value: (
            <StyledContainerRight>
              {translateType(summaryTicket.typePayment)}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Total pagado',
          value: (
            <StyledContainerRight>
              {convertNumberToMoney(summaryTicket.payment + summaryTicket.fee)}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Comisión BEES Pay',
          value: (
            <StyledContainerRight>
              {summaryTicket.fee !== undefined
                ? convertNumberToMoney(summaryTicket.fee)
                : convertNumberToMoney(0)}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Referencia',
          value: (
            <StyledContainerRight>
              {summaryTicket.authorizationCode}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Pago con tarjeta',
          value: (
            <StyledContainerRight>
              {summaryTicket.cardDigit ? `**${summaryTicket.cardDigit}` : `--`}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Tipo de Tarjeta',
          value: (
            <span>
              {' '}
              {summaryTicket.typeCard ? summaryTicket.typeCard : `--`}
            </span>
          )
        },
        {
          parameter: 'Status',
          value: (
            <StyledContainerRight>
              {translateStatus(summaryTicket.status)}
            </StyledContainerRight>
          )
        }
      ]
      break
    case 'cashRegister':
      rowBody = [
        {
          parameter: 'Comercio',
          value: (
            <StyledContainerRight>{summaryTicket.company}</StyledContainerRight>
          )
        },
        {
          parameter: 'Operación',
          value: <StyledContainerRight>Venta con tarjeta</StyledContainerRight>
        },
        {
          parameter: 'Forma de pago',
          value: <StyledContainerRight>Tarjeta</StyledContainerRight>
        },
        {
          parameter: 'Total pagado',
          value: (
            <StyledContainerRight>
              {convertNumberToMoney(summaryTicket.payment)}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Tarifa BEES Pay',
          value: (
            <StyledContainerRight>
              {summaryTicket.fee !== undefined
                ? convertNumberToMoney(summaryTicket.fee)
                : convertNumberToMoney(0)}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Pago con tarjeta',
          value: (
            <StyledContainerRight>
              {summaryTicket.cardDigit ? `**${summaryTicket.cardDigit}` : `--`}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Tipo de Tarjeta',
          value: (
            <span>
              {' '}
              {summaryTicket.typeCard ? summaryTicket.typeCard : `--`}
            </span>
          )
        },
        {
          parameter: 'Referencia',
          value: (
            <StyledContainerRight>
              {summaryTicket.reference}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Status',
          value: (
            <StyledContainerRight>
              {translateStatus(summaryTicket.status)}
            </StyledContainerRight>
          )
        }
      ]
      break
    case 'cancelation':
      rowBody = [
        {
          parameter: 'Compañia',
          value: <StyledContainerRight>Bess Pay</StyledContainerRight>
        },
        {
          parameter: 'Operación',
          value: (
            <StyledContainerRight>
              {translateTypeSmall(summaryTicket.typePayment)}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Total pagado',
          value: (
            <StyledContainerRight>
              {convertNumberToMoney(summaryTicket.payment + summaryTicket.fee)}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Forma de pago',
          value: (
            <StyledContainerRight>
              {summaryTicket.cardDigit !== undefined ? 'Tarjeta' : 'Débito'}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Referencia',
          value: (
            <StyledContainerRight>
              {summaryTicket.authorizationCode}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Pago con tarjeta',
          value: (
            <StyledContainerRight>
              {summaryTicket.cardDigit ? `**${summaryTicket.cardDigit}` : `--`}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Tipo de Tarjeta',
          value: (
            <span>
              {' '}
              {summaryTicket.typeCard ? summaryTicket.typeCard : `--`}
            </span>
          )
        },
        {
          parameter: 'Status',
          value: (
            <StyledContainerRight>
              {translateStatus(summaryTicket.status)}
            </StyledContainerRight>
          )
        }
      ]
      break
    case 'b2b':
      rowBody = [
        {
          parameter: 'Compañia',
          value: (
            <StyledContainerRight>{summaryTicket.company}</StyledContainerRight>
          )
        },
        {
          parameter: 'Número pedido',
          value: (
            <StyledContainerRight>
              {summaryTicket.ticketId}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Total pagado',
          value: (
            <StyledContainerRight>
              {convertNumberToMoney(summaryTicket.payment)}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Tarifa BEES Pay',
          value: (
            <StyledContainerRight>
              {summaryTicket.fee !== undefined
                ? convertNumberToMoney(summaryTicket.fee)
                : convertNumberToMoney(0)}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Autorización',
          value: (
            <StyledContainerRight>
              {summaryTicket.reference}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Método de Pago',
          value: <StyledContainerRight>BEES Pay</StyledContainerRight>
        },
        {
          parameter: 'Status',
          value: (
            <StyledContainerRight>
              {translateStatus(summaryTicket.status)}
            </StyledContainerRight>
          )
        }
      ]
      break
    case 'fundWallet':
      rowBody = [
        {
          parameter: 'Comercio',
          value: (
            <StyledContainerRight>
              {summaryTicket.company ? summaryTicket.company : 'Bees Pay'}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Operación',
          value: <StyledContainerRight>Recarga de Saldo</StyledContainerRight>
        },
        {
          parameter: 'Forma de pago',
          value: (
            <StyledContainerRight>
              {summaryTicket.cardDigit !== undefined ? 'Tarjeta' : 'Débito'}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Total pagado',
          value: (
            <StyledContainerRight>
              {convertNumberToMoney(summaryTicket.payment + summaryTicket.fee)}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Tarifa BEES Pay',
          value: (
            <StyledContainerRight>
              {summaryTicket.fee !== undefined
                ? convertNumberToMoney(summaryTicket.fee)
                : convertNumberToMoney(0)}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Referencia',
          value: (
            <StyledContainerRight>
              {summaryTicket.authorizationCode}
            </StyledContainerRight>
          )
        },
        {
          parameter: 'Status',
          value: (
            <StyledContainerRight>
              {translateStatus(summaryTicket.status)}
            </StyledContainerRight>
          )
        }
      ]
      break
    default:
      rowBody = []
  }

  const tileRow = {
    rowBody
  }
  return tileRow
}
