import React from 'react'
import { List } from 'antd'
import IconComponent from '../Icon'
import Factura from '../../data/assets/icons/factura.png'
import {
  HourContainer,
  ParagraphDate,
  ParagraphHour,
  ReportContainer
} from './styledComponents'
import { translateType } from '../../containers/BeesPayReportContainerByDayTickets/utils'
import moment from 'moment/moment'
import { capitalizeFirstLetter } from '../../utils/general'

type props = {
  DataHeader: BeesPayTypes.DetailTransaction[]
  ticketID?: string
  storeId?: string
  type?: string | null | boolean
}

const TicketHeader: React.FC<props> = ({ DataHeader }) => {
  const ListItem = List.Item
  const ListItemMeta = List.Item.Meta
  const date = capitalizeFirstLetter(
    moment(Number(DataHeader[0].createdAt)).format('dddd DD MMM YYYY')
  )

  const time = moment(parseInt(DataHeader[0].createdAt, 10)).format(' hh:mm A')

  return (
    <ReportContainer>
      <List
        itemLayout="horizontal"
        dataSource={DataHeader}
        renderItem={(item: BeesPayTypes.DetailTransaction) => {
          return (
            <ListItem>
              <IconComponent icon={Factura} height={24} width={24} />
              <ListItemMeta
                title={`Folio ${item.transactionServiceID.split('-')[0]}`}
                description={`${translateType(item.transactionTypeID)}`}
              />
            </ListItem>
          )
        }}
      />
      <HourContainer>
        <ParagraphDate>{date}</ParagraphDate>
        <ParagraphHour>{time}</ParagraphHour>
      </HourContainer>
    </ReportContainer>
  )
}

export default TicketHeader
