// Packages
import React from 'react'

// Components
import WithPrivateRoute from '../../../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../../../components/SEO'

import LayoutMain from '../../../../layout'
import BeesPayReportTicketContainer from '../../../../containers/BeesPayReportTicketContainer'

const BeesDetailTicket: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="BeesPay Ticket" />
      <WithPrivateRoute component={BeesPayReportTicketContainer} />
    </LayoutMain>
  )
}

export default BeesDetailTicket
