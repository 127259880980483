import React, { useEffect, useState } from 'react'
import { getParameter } from '../../utils/queryString'
import BeesPayReportTicketDetail from '../../components/BeesPayReportTicketDetail'
import { navigate } from 'gatsby'
import { transformBeesQueryTicketDetailToComponent } from './utils'
import { TileRowsProps } from '../../components/Storybook/TileRows'
import { useQuery, useLazyQuery } from '@apollo/client'
import {
  GET_TICKET_DETAIL,
  GET_TICKET_DETAIL_CANCELLED
} from '../../graphql/queries/Reports/beespay'
import Loading from '../../components/Shared/Loading'

const BeesPayReportTicketContainer: React.FC = () => {
  const [tileRow, setTileRow] = useState<TileRowsProps>({ rowBody: [] })
  const ticketID = getParameter('ticketID') ? getParameter('ticketID') : ''
  const [transactionData, setTransactionData] =
    useState<BeesPayTypes.DetailTransaction>({
      amount: 0,
      amountCurrency: '',
      authorizationCode: '',
      cardBank: '',
      cardBrand: '',
      cardDigit: '',
      cardInstrument: '',
      createdAt: '',
      empresarioID: '',
      reference: '',
      status: '',
      storeID: '',
      transactionCode: '',
      transactionCommission: 0,
      transactionFee: 0,
      transactionServiceID: '',
      transactionServiceName: '',
      transactionServiceDescription: '',
      transactionTypeID: ''
    })

  const { data: detailTicketData, loading: detailTicketLoading } = useQuery(
    GET_TICKET_DETAIL,
    {
      variables: {
        ticketID: ticketID
      }
    }
  )

  const [
    refetchTicketIDCancelled,
    { data: detailCancellationData, loading: detailCancellationLoading }
  ] = useLazyQuery(GET_TICKET_DETAIL_CANCELLED, {
    fetchPolicy: 'cache-and-network'
  })

  const isCancellation = (getTrxDetail: any) => {
    const { reference, transactionTypeID } = getTrxDetail
    if (transactionTypeID === 'cancelation') {
      const variables = { ticketID: reference }
      refetchTicketIDCancelled({
        variables
      })
    }
  }

  useEffect(() => {
    if (detailTicketData && !detailTicketLoading) {
      const { getTrxDetail } = detailTicketData
      isCancellation(getTrxDetail)
      const { cardBank, cardDigit, cardInstrument } = detailCancellationData
        ? detailCancellationData.getTrxDetail
        : getTrxDetail
      const { tileRow: tileRowsTransformed } =
        transformBeesQueryTicketDetailToComponent({
          ...getTrxDetail,
          cardDigit,
          cardBank,
          cardInstrument
        })
      setTileRow(tileRowsTransformed)
      setTransactionData(getTrxDetail)
    }
  }, [detailTicketData, detailCancellationData, detailTicketLoading])

  const onClickBack = () => {
    localStorage.removeItem('type')
    navigate(-1)
  }
  return (
    <>
      {!detailTicketLoading && !detailCancellationLoading && (
        <BeesPayReportTicketDetail
          onClickBack={onClickBack}
          data={transactionData}
          type={transactionData.transactionTypeID}
          tileRows={tileRow}
        />
      )}
      {detailTicketLoading && <Loading />}
    </>
  )
}

export default BeesPayReportTicketContainer
