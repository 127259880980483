/* istanbul ignore file */
import React from 'react'
import { ReportContentHeader } from '../../containers/TotalOperationsContainer/styledComponents'
import CustomButton from '../Storybook/customButton'
import { LeftOutlined } from '@ant-design/icons'
import { StyledReportTitle } from '../../containers/FilterSalesReportContainer/styledComponents'
import { Row } from 'antd'
import TicketHeader from '../TicketHeader'
import { ReportBeesContainer } from './styledComponents'
import { CardRowsBees } from '../Storybook/TileRows'
import {
  ParagraphTotal,
  TotalContainer
} from '../../containers/ReportTickets/styledComponents'
import TotalAmount from '../TotalAmount'
import { translateTypeSmall } from '../../containers/BeesPayReportTicketContainer/utils'
import TicketNotFound from '../TicketNotFound'

const BeesPayReportTicketDetail = ({
  onClickBack,
  data,
  type,
  tileRows
}: {
  onClickBack: () => void
  data: BeesPayTypes.DetailTransaction
  ticketID?: string
  type: string
  tileRows: any
}) => {
  return (
    <>
      <Row gutter={16}>
        <ReportContentHeader>
          <CustomButton
            id="button-icon-back-sales-report"
            name="buttonIconBackSalesReport"
            className="button-icon-back-sales-report"
            dataTestId="data-test-button-icon-back-sales-report"
            icon={<LeftOutlined />}
            onClick={onClickBack}
          />
          <StyledReportTitle>Detalle del ticket</StyledReportTitle>
        </ReportContentHeader>
      </Row>
      <ReportBeesContainer>
        {data.transactionServiceName !== '' && (
          <>
            <TicketHeader DataHeader={[data]} />
            <CardRowsBees {...tileRows} />
            <TotalContainer>
              <ParagraphTotal>
                {translateTypeSmall(type)} <br /> {data.transactionServiceName}
              </ParagraphTotal>
              <TotalAmount
                amount={
                  data.transactionTypeID != 'billPayment'
                    ? data.amount.toString()
                    : (data.amount + data.transactionFee).toString()
                }
                amountSize={'24'}
                currecySize={'12'}
                centsSize={'12'}
              />
            </TotalContainer>
          </>
        )}
        {data.transactionServiceID === '' && (
          <>
            <TicketNotFound />
          </>
        )}
      </ReportBeesContainer>
    </>
  )
}

export default BeesPayReportTicketDetail
